import React from "react";

interface Props {
  src?: any;
  alt?: string;
}

export default function PostHero({ src, alt }: Props) {
  return (
    <div className="bg-bg-secondary my-24">
      <div className="container md:min-h-[400px] mx-auto md:px-20 md:pt-20 px-8 pt-8 flex items-end justify-center relative">
        {!!src && <img src={src} alt={alt} className="w-full md:border-4 border-2 md:border-b-0 border-b-0 border-text-secondary md:rounded-t-2xl rounded-t-lg object-contain" />}
      </div>
    </div>
  );
}
