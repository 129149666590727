import React from "react";
import PostTitle from "../components/PostTitle";
import PostHero from "../components/PostHero";
import PostSection from "../components/PostSection";
import TextBlock from "../components/TextBlock";
import PostSectionTitle from "../components/PostSectionTitle";
import TextGroup from "../components/TextGroup";
import PostLayout from "../components/PostLayout";

export default function CommentPreviews() {
  return (
    <PostLayout previousPath="/assignment-tool">
      <PostTitle
        title="Designing for data transparency"
        company="Thoughtwire"
        time="2022 - Now"
        role="Product Designer"
        output="Mockups, Prototypes, UI components, Feature documentation"
      >
        During my internship at Quora, I worked with the distribution team on a
        project to ensure that there was no bias on the Quora feed against short
        content. While Quora allows for both long and short-form content, we
        noticed that short content on the platform was not getting as much
        distribution. Working with a team of ML engineers and data analysts, I
        explored comment previews as a way to promote comment engagement on
        content on Quora.
      </PostTitle>

      <PostHero src="https://res.cloudinary.com/drsp4xifi/image/upload/v1626880154/Portfolio_v2/Group_247_bn3yit.png" />

      <PostSection>
        <TextGroup>
          <h4 className="uppercase font-bold text-lg text-text-tertiary absolute top-0 tracking-wide">
            The Challenge
          </h4>
          <PostSectionTitle>Discovery</PostSectionTitle>
          <TextBlock>
            On the Quora feed, long-form content is collapsed by default and
            expanded when a user clicks on the "read more" link. This kind of
            user interaction helps the machine learning model learn and
            consequently improves the feed experience for users. For short
            content, however, this feature does not exist. for users.
          </TextBlock>
          <TextBlock>
            The result is a possibly skewed prediction model that may not have
            enough information to understand users' perception of short content.
            As part of our efforts to mitigate this, I was tasked with
            implementing comment previews on short content. A benefit of this is
            that with increased comment engagement, we can learn more about
            content that users care for, and this, in turn, creates a better
            feed experience
          </TextBlock>
        </TextGroup>
      </PostSection>

      <PostSection>
        <TextGroup>
          <PostSectionTitle>
            We are phased with building a solution that users can trust inspite
            of data issues?
          </PostSectionTitle>
          <TextBlock>
            Buildings using Precision Hub generate large amounts of data. These
            are mostly multi-storey buildings with data coming from various
            systems, including HVAC, lighting, security, and sometimes even CSV
            files.
          </TextBlock>
          <TextBlock>
            It is important that despite these challenges, users can still trust
            the platform to give them the information they need.
          </TextBlock>
        </TextGroup>
      </PostSection>

      <div className="py-20 bg-bg-secondary">
        <PostSection>
          <TextGroup>
            <h4 className="uppercase font-bold text-lg text-text-tertiary absolute top-0 tracking-wide">
              Key design decisions
            </h4>
            <PostSectionTitle>
              The same goal: Guide users to what needs attention
            </PostSectionTitle>
            <TextBlock>
              When displaying any metric, our goal is to help users understand
              the data and gain meaningful insight from it. This is particularly
              important when making information digestible by aggregation. For
              instance, a security officer would find it more useful to know
              that there’s 1 jammed door on a floor, rather than simply seeing
              that there are ten doors.
            </TextBlock>
            <TextBlock>
              We achieve this by designing components with specific constraints
              that guide the configuration process, ensuring the right questions
              are asked.The status component, for example, requires details like
              a label for the device, a key piece of information as a subtitle,
              an icon, and a colour to signal urgency. These requirements ensure
              that wherever the component is used it can deliver meaningful
              insights.{" "}
            </TextBlock>
          </TextGroup>
        </PostSection>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Reducing Confusion with a Clear Information Hierarchy
            </PostSectionTitle>
            <TextBlock>
              Another way we build trust is in the way that we organize
              information. We had an issue where on the floor details panel,
              users found it confusing to see 2 alerts listed in the "Alerts"
              section and 8 in the "Current Metrics" section. The confusion was
              because the current metrics section displayed aggregated data
              while the alerts section showed floor-level data.{" "}
            </TextBlock>
            <TextBlock>
              To address this, we separated aggregate data from floor-level
              data, making it easier for users to quickly grasp the data’s
              scope. Arriving at this solution required close collaboration with
              engineers to understand the data structure and how we were
              aggregating data. With this understanding, I was able to design a
              holistic solution that could be applied across similar views.{" "}
            </TextBlock>
          </TextGroup>
        </PostSection>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              When in doubt, say what you know about the data. Don't make
              assumptions.
            </PostSectionTitle>
            <TextBlock>
              Sometimes we encounter data that may not include some details we
              expect, such as an alert linked to a device with a floor location
              but no room information. In such cases, we present the data
              exactly as it is instead of making assumptions. In practice, this
              means stating that the alert is from a device on floor 2 rather
              than implying it affects the entire floor.
            </TextBlock>
            <TextBlock>
              Additionally, when we aggregate data, we ensure that metrics are
              clearly labeled to indicate they are aggregate values. Measures
              like these ensure that users can trust the information we present.
            </TextBlock>
          </TextGroup>
        </PostSection>

        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Handling Missing Data Gracefully
            </PostSectionTitle>
            <TextBlock>
              In some cases, certain data points the platform expects simply
              don't exist in the source system and fallbacks are necessary to
              ensure the UI doesn't appear broken. In other cases, the data is
              unavailable because the user lacks the appropriate permissions to
              view it, so it's completely hidden. When information is hidden,
              the interface should adapt gracefully without breaking or looking
              odd
            </TextBlock>
            <TextBlock>
              A good example of this in the room details panels which has
              different sections. We can enable or disable a section depending
              on what data a user has access to and the UI still looks as
              intended.
            </TextBlock>
          </TextGroup>
        </PostSection>
      </div>

      <div>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Something about how working with data i
            </PostSectionTitle>
            <TextBlock>
              When displaying any data point or metric, our goal is to help
              users understand the data and gain meaningful insight from it.
              This is particularly important when making information digestible
              by aggregation. For instance, a security officer would find it
              more useful to know that there’s 1 jammed door on a floor, rather
              than simply seeing that there are ten doors. We achieve this by
              designing components with specific constraints that guide the
              configuration process, ensuring the right questions are asked.
            </TextBlock>
          </TextGroup>
        </PostSection>
      </div>
    </PostLayout>
  );
}
