import React from "react";
import amaMemoji from "../assets/ama-memoji.png";
import { Link } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";

interface Props {
  previousPath?: string;
  nextPath?: string;
  children: any;
  homePage?: boolean;
}

export default function PostLayout({
  previousPath,
  nextPath,
  children,
  homePage
}: Props) {
  return (
    <div className="flex flex-col ">
      <div className="fixed top-0 left-0 bg-bg-primary/5 w-full z-10 ">
        <nav className="container mx-auto">
          <div className=" flex py-3 justify-between items-center">
            <Link to="/" className="" title="Home">
              <img src={amaMemoji} alt="Ama's memoji" className="h-12" />
            </Link>
            <div className="flex gap-3">
              {!!previousPath && (
                <Link
                  to={previousPath}
                  className="flex justify-center items-center bg-bg-secondary hover:bg-bg-accent hover:text-bg-primary rounded-full px-3 h-9 w-24 text-sm font-semibold transition-colors"
                >
                  Previous
                </Link>
              )}
              {!!nextPath && (
                <Link
                  to={nextPath}
                  className="flex justify-center items-center bg-bg-secondary hover:bg-bg-accent hover:text-bg-primary  rounded-full px-3 h-9 w-24 text-sm font-semibold transition-colors"
                >
                  Next
                </Link>
              )}
              <ThemeToggle />
            </div>
          </div>
        </nav>
      </div>
      <div className={`relative top-20 md:pb-0 ${!homePage && "pb-24"}`}>{children}</div>
    </div>
  );
}
