import React from "react";
import phubHero from "../assets/floor-level.png";
import nursePopover from "../assets/select-nurse-popover.png";
import PortfolioCard from "../components/PortfolioCard";
import TextGroup from "../components/TextGroup";
import TextBlock from "../components/TextBlock";
import PostSectionTitle from "../components/PostSectionTitle";
import PostImage from "../components/PostImage";
import PostLayout from "../components/PostLayout";

export default function Home() {
  return (
    <PostLayout homePage>
      <div className="bg-bg-primary pt-20">
        <div className="container mx-auto md:my-12 pb-20">
          <div className="md:h-[100px] flex flex-col items-center mb-16">
            <h1 className="font-display md:text-7xl text-6xl font-bold ">Chiamaka</h1>
            <p className="font-semibold text-xl text-text-secondary">Product Designer</p>
          </div>

          <div className="flex flex-col gap-20">
            <PortfolioCard
              title="Building Trust through Data Clarity"
              subtitle="Providing facility operators with meaningful insights from building data despite data limitiations"
              imageSrc={phubHero}
              imageAlt="hero"
              tags="Design • Research • Etc"
              linkPath="/precision-hub"
              company="Thoughtwire • 2023 - Now"
            />
            <PortfolioCard
              title="An assignment tool for decision making"
              subtitle="Supporting Charge Nurses in managing patient-assignments throughout every stage of the workflow"
              imageSrc={nursePopover}
              imageAlt="hero"
              tags="Design • Research • Etc"
              linkPath="/assignment-tool"
              company="Thoughtwire • 2024"
            />
            {/* <PortfolioCard
              company="Quora • 2021"
              title="Exploring comment previews for short-content"
              subtitle="During my internship, "
              imageSrc={
                "https://res.cloudinary.com/drsp4xifi/image/upload/v1625133773/Portfolio_v2/Comment%20Preview/Image_15_tib0tj.png"
              }
              imageAlt="hero"
              tags="Design • Research • Etc"
              linkPath="/comment-previews"
            /> */}
          </div>
        </div>
        <div className="bg-bg-secondary py-12 px-4">
          <div className="container max-w-4xl mx-auto py-12">
            <div className="max-w-2xl relative">
              <TextGroup>
                <PostImage
                  src="https://res.cloudinary.com/drsp4xifi/image/upload/v1624893246/Portfolio_v2/About/Ellipse_75_ujgztj.png"
                  alt=""
                  className="w-[150px] ml-0 my-8"
                />

                <PostSectionTitle>Hello! 👋🏾</PostSectionTitle>
                <TextBlock>
                  My name is Chiamaka <em>(Chee-ah-mah-kah)</em>, a product
                  designer at ThoughtWire.
                </TextBlock>
                <TextBlock>
                  I enjoy working in environments that leverage data — whether
                  collecting, analyzing, or using it to drive decisions. I love
                  to build the things I design and enjoy prototyping with code.
                </TextBlock>
                <TextBlock>
                  When I’m not working, I'm trying out new recipes
                  🍱 🍚 🍉 or watching a movie 🍿.
                </TextBlock>
                <TextBlock>
                  Find me on{" "}
                  <a
                    href="https://www.linkedin.com/in/chiamakaiwuanyanwu/"
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold underline text-bg-accent"
                  >
                    LinkedIn
                  </a>
                </TextBlock>
              </TextGroup>
            </div>
          </div>
        </div>
      </div>
    </PostLayout>
  );
}
