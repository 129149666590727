import React from "react";
import { useInView } from "react-intersection-observer";

interface Props {
  src: string;
  alt: string;
  className?: string;
  caption?: string
}

export default function PostImage({ src, alt, className, caption }: Props) {
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the image is in view
    triggerOnce: true, // Only trigger once
  });

  return (
    <figure className="relative">
      <img
        ref={ref}
        src={src}
        alt={alt}
        className={`mx-auto ${className ? className : "h-[450px]"} ${
          inView ? "animate-fadeIn" : "opacity-0"
        }`}
      />
      {!!caption && <figcaption className="font-display italic text-text-secondary text-sm text-center mt-4 md:w-[65%] m-auto">{caption}</figcaption>}
    </figure>
  );
}
