import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  subtitle: string;
  company: string;
  tags: string;
  imageSrc: any;
  imageAlt: string;
  linkPath: string;
}

export default function PortfolioCard({
  title,
  subtitle,
  company,
  imageSrc,
  imageAlt,
  linkPath,
}: Props) {
  return (
    <Link to={linkPath} className="max-w-5xl mx-auto">
      <div className="md:h-[400px] group overflow-hidden transition-all rounded-3xl grid md:grid-cols-2 grid-cols-1 hover:scale-105 duration-300">
        <div className="md:p-10 p-4 h-full flex flex-col gap-6 justify-center col-span-1 md:order-1 order-2">
          <span className="uppercase text-sm text-text-secondary font-semibold tracking-wide">
            {company}
          </span>
          <div>
            <h2 className="font-display text-3xl font-black text-text-primary mb-2 leading-tight">
              {title}
            </h2>
            <p className="text-text-secondary leading-7">{subtitle}</p>
          </div>

          <span className="text-text-accent font-semibold group-hover:underline">
            Read case study
          </span>
        </div>
        <div className="md:h-full h-[300px] pt-4 md:pr-4 relative overflow-hidden col-span-1 md:order-2 order-1">
          <img
            src={imageSrc}
            alt={imageAlt}
            className="w-[full] object-cover top-0 rounded-2xl scale-90 group-hover:shadow-sm"
          />
          <div className="absolute bottom-0 left-0 right-0 h-1/4 gradient-bg" />
        </div>
      </div>
    </Link>
  );
}
