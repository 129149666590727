import React from "react";
import PostTitle from "../components/PostTitle";
import PostHero from "../components/PostHero";
import PostSection from "../components/PostSection";
import TextBlock from "../components/TextBlock";
import PostSectionTitle from "../components/PostSectionTitle";
import TextGroup from "../components/TextGroup";
import PostLayout from "../components/PostLayout";
import PostImage from "../components/PostImage";

import researchNotes from "../assets/research-notes.png";
import shiftDetailsGIF from "../assets/shift-details.gif";
import nursePopover from "../assets/select-nurse-popover.png";
import patientsList from "../assets/patients-list.png";
import heroImg from "../assets/patient-ass-hero.png";
import careBadge from "../assets/care-badge.svg";

export default function AssignmentTool() {
  return (
    <PostLayout previousPath="/precision-hub">
      <PostTitle
        title="An assignment tool for decision making"
        company="Thoughtwire"
        time="2024"
        role="Product Design"
        output="Research, Mockups, Prototypes"
      >
        In hospitals patient safety is key, however, balancing nursing workload
        is also a challenge. By gaining a deeper understanding of the Charge
        Nurse’s workflow, we redesigned our nurse-patient assignment tool to
        support decision making at every stage of the shift, even as patients'
        needs change.
      </PostTitle>

      <PostHero
        src={heroImg}
        alt="A screenshot of the patient view of the patient assignment showing a table with a list of patients."
      />
      <PostSection>
        <div className="flex flex-col gap-24">
          <TextGroup>
            <h4 className="uppercase font-bold text-lg text-text-tertiary mb-14 relative -top-10  tracking-wide">
              The Challenge
            </h4>
            <PostSectionTitle>
              Balancing patient safety and nursing workload is difficult
            </PostSectionTitle>
            <TextBlock>
              Charge Nurses (CNs) are responsible for assigning nurses to
              patients. They manage limited staffing resources to ensure
              continuity of care while keeping workload distribution fair and
              balanced.
            </TextBlock>
            <TextBlock>
              The decision-making process can get complex, requiring CNs to
              consider factors like staffing availability, patient acuity, and
              nursing expertise when making assignments, all to ensure patient's
              get the right care.
            </TextBlock>
          </TextGroup>

          <TextGroup>
            <PostSectionTitle>
              CNs need support in decision making at every stage of a shift
            </PostSectionTitle>
            <TextBlock>
              During our discovery, I learned that charge nurses (CNs) make
              assignment decisions throughout their shift as patients' needs
              change. To do this effectively, they must track critical
              information, such as previous caregivers for a patient and any
              updates in a patient’s condition.
            </TextBlock>
            <TextBlock>
              Our goal, then, was to equip CNs with the information necessary to
              make effective patient assignments—whether at the start, during,
              or end of a shift.
            </TextBlock>
          </TextGroup>
        </div>
        <PostImage
          src={researchNotes}
          alt="A screenshot showing sticky notes in figjam"
          className="md:h-[400px] w-full md:w-auto shadow-sm rounded-2xl bg-bg-tertiary"
          caption="My research revealed that CNs make decisions at every stage of the shift"
        />
      </PostSection>

      <div className="py-20 mb-14 mt-40 bg-bg-secondary">
        <PostSection>
          <TextGroup>
            <h4 className="uppercase tracking-wide font-bold text-lg text-text-tertiary absolute top-0">
              Key design decisions
            </h4>
            <PostSectionTitle>
              A view that prioritizes patients
            </PostSectionTitle>
            <TextBlock>
              My initial design explorations focused on a nurse view to
              visualize workload. However, after speaking with a nurse, it was
              clear that for CNs patient safety is always the top priority. This
              insight led me to shift the primary view to one that was patient
              focused.
            </TextBlock>
            <TextBlock>
              The patient view helps CNs see at a glance which patients need
              immediate care. With filtering and multi-select options, it
              supports common workflows like assigning multiple patients within
              the same room to the same nurse, which can help to reduce fatigue.
            </TextBlock>
          </TextGroup>
          <TextGroup>
            <PostImage
              src={patientsList}
              alt="An image with sticky notes containing quotes from nurses "
              className="w-full rounded-lg shadow-md"
              caption="The patient view supports common workflows like assigning multiple patients to the same nurse"
            />
          </TextGroup>
        </PostSection>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Finding the right nurse with Nurse Attributes
            </PostSectionTitle>
            <TextBlock>
              When assigning a nurse to a patient, the CN's goal is to choose
              the best nurse for each patient's needs. To support this, the
              nurse selection pop-up displays key nurse attributes, such as no.
              of assignments, role, a <em>care badge</em> (which indicates if a
              nurse has cared for a patient before), helping CNs assess who is
              best fit.
            </TextBlock>
            <TextBlock>
              Although this view focuses on patients, CNs can still see how
              making an assignment will impact the nurse's workload when they
              hover on the nurse in the pop-up. This subtle feature allows CNs
              to make decisions that consider both patient needs and nurse
              workload, without shifting focus away from patient care.
            </TextBlock>
          </TextGroup>
          <PostImage
            src={nursePopover}
            alt="A screenshot of the Nurse Selection Pop up which displays a list of nurses with attributes like the number of assignments, role and shift schedule. The first nurse in the list has a care badge."
            className="h-[350px] shadow-md"
            caption="The nurse selection pop-up displays information to help CNs select the right nurse for each patient"
          />
        </PostSection>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Bringing warmth to the UI with Care badges
            </PostSectionTitle>
            <TextBlock>
              A major consideration in patient assignments is whether the nurse
              has previously cared for the patient. Since this is crucial for
              continuity of care, I knew it was equally important to highlight
              this in the UI when choosing a nurse.
            </TextBlock>
            <TextBlock>
              In selecting the icon, my goal was to convey trust and
              familiarity. I experimented with icons like stars, shields, and
              medical suitcases, but each felt either too generic or confusing.
              Focusing on the concept of care led me to the final choice—a
              shield with a heart—which became the <em>care badge</em>. I
              especially love the warmth that the pink color and heart bring to
              the UI, aligning perfectly with the focus on patient care.
            </TextBlock>
          </TextGroup>
          <PostImage
            src={careBadge}
            alt="A pink badge with a heart in the middle."
            className="rounded-2xl h-40"
            caption="The care badge represents trust and care and brings warmth to the interface"
          />
        </PostSection>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              An interface that supports documentation
            </PostSectionTitle>
            <TextBlock>
              With the previous design, when a shift started, the previous shift
              was removed, and the user no longer had access to it. However,
              from my research, it was clear that CNs needed to reference events
              that happened in the past shift when planning for the next.
            </TextBlock>
            <TextBlock>
              To address this need, I proposed the shift summary panel. This
              panel not only helps CNs track events throughout their shift with
              updates, but also acts as a valuable documentation tool for
              everything that occurred during the shift. My proposal was well
              received, as it directly addressed the challenge of maintaining
              continuity between shifts.
            </TextBlock>
          </TextGroup>
          <PostImage
            src={shiftDetailsGIF}
            alt="A GIF of the shift summary panel, illustrating how an update is hidden from the panel when it is read."
            className="rounded-2xl w-full"
            caption="The shift summary section tracks events that happen during a shift, and provides documentation to support shift planning and handover"
          />
        </PostSection>
      </div>

      <PostSection>
        <TextGroup>
          <h4 className="uppercase font-bold text-lg text-text-tertiary absolute top-0 tracking-wide">
            The Impact
          </h4>
          <PostSectionTitle>
            A tool that better aligns with the workflow of its users
          </PostSectionTitle>
          <TextBlock>
            By focusing on charge nurses' needs at each shift phase, the
            assignment tool now provides even more support in making assignment
            decisions throughout the shift. This approach of aligning the tool
            with the charge nurse's workflow was well received by all
            stakeholders.
          </TextBlock>
        </TextGroup>
      </PostSection>
    </PostLayout>
  );
}
