import React from "react";

interface Props {
  company: string;
  time: string;
  title: string;
  role: string;
  output: string;
  children?: any;
}

export default function PostTitle({
  company,
  time,
  title,
  role,
  output,
  children,
}: Props) {
  return (
    <div className="container mx-auto p-4 md:pt-12">
      <div className="max-w-3xl">
        <div className="flex flex-col gap-2">
          <div className="flex gap-3 font-bold text-lg text-text-accent pb-6 tracking-wide">
            <span className="uppercase"> {company} </span>
            <span className="text-text-tertiary font-medium">{time}</span>
          </div>
          <h1 className="font-display md:text-6xl text-3xl font-bold">{title}</h1>
          <div className="pb-6">
            <div className="flex flex-col justify-center">
              <div className="md:flex gap-2 text-text-secondary">
                <span className="w-16 font-semibold">Role</span>{" "}
                <span className="">{role}</span>
              </div>
              <div className="md:flex gap-2 text-text-secondary">
                <span className="w-16 text-text-secondary font-semibold">
                  Output
                </span>{" "}
                <span className="">{output}</span>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
